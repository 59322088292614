import React from "react"

import { RiExternalLinkLine } from "react-icons/ri"

import Icon from "../../../components/icon/icon"

import Container from "../../../components/container/container"
import Section from "../../../components/section/section"
import TextBlock from "../../../components/text-block/text-block"

import BoVanGelderenPortfolioWebp from "../../../images/portfolio/mockups/bo-van-gelderen-portfolio/bo-van-gelderen.webp"
import BoVanGelderenPortfolioPng from "../../../images/portfolio/mockups/bo-van-gelderen-portfolio/bo-van-gelderen.png"

import CadeautjeKopenPortfolioWebp from "../../../images/portfolio/mockups/cadeautje-kopen-portfolio/cadeautje-kopen.webp"
import CadeautjeKopenPortfolioPng from "../../../images/portfolio/mockups/cadeautje-kopen-portfolio/cadeautje-kopen.png"

const PortfolioBlock = ({ darkBackground, backgroundColor }) => {
  return (
    <Section
      id="portfolio"
      darkBackground={darkBackground}
      style={{ backgroundColor }}
    >
      <div className="portfolio-content-holder">
        <Container type="default">
          <TextBlock
            title="Portfolio."
            subtitle="Recente projecten waar wij aan hebben gewerkt."
          />
          <div className="portfolio-content">
            <div className="portfolio-piece">
              <div className="portfolio-image">
                <picture>
                  <source
                    srcSet={CadeautjeKopenPortfolioWebp}
                    type="image/webp"
                  />
                  <source
                    srcSet={CadeautjeKopenPortfolioPng}
                    type="image/png"
                  />
                  <img
                    src={CadeautjeKopenPortfolioPng}
                    alt="Mockup of product"
                  />
                </picture>
              </div>
              <div className="portfolio-info">
                <div>
                  <h3 className="portfolio-header">Cadeautje-kopen.nl</h3>
                  <p className="portfolio-text">
                    Een toffe blog én webwinkel gemaakt met Wordpress en
                    WooCommerce met geïntegreerde én veilige betalingsmethoden.
                    De website staat bovenaan in de zoekresulaten en is volledig
                    geoptimaliseerd om snel en responsive te zijn.
                  </p>
                  <div className="portfolio-link">
                    <Icon icon={<RiExternalLinkLine />} />
                    <a
                      href="http://cadeautje-kopen.nl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Bezoek cadeautje-kopen.nl
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="portfolio-piece">
              <div className="portfolio-image">
                <div></div>
                <picture>
                  <source
                    srcSet={BoVanGelderenPortfolioWebp}
                    type="image/webp"
                  />
                  <source srcSet={BoVanGelderenPortfolioPng} type="image/png" />
                  <img
                    src={BoVanGelderenPortfolioPng}
                    alt="Mockup of product"
                  />
                </picture>
              </div>
              <div className="portfolio-info">
                <div></div>
                <div>
                  <h3 className="portfolio-header">
                    Bo van Gelderen portfolio
                  </h3>
                  <p className="portfolio-text">
                    Een moderne portfolio website voor een student waarop ze
                    haar eigen gemaakte kleding tentoonsteld. De website is
                    overzichtelijk, snel en bruikbaar op zowel mobiel, tablet en
                    computer.
                  </p>
                  <div className="portfolio-link">
                    <Icon icon={<RiExternalLinkLine />} />
                    <a
                      href="http://bovangelderen.nl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Bezoek bovangelderen.nl
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Section>
  )
}

export default PortfolioBlock
