import { Link } from "gatsby"
import React from "react"

import { RiSearchLine, RiChat3Line, RiPenNibLine, RiCodeSSlashLine, RiFileCodeLine, RiFingerprint2Line } from 'react-icons/ri';

import Container from "../../../components/container/container"
import Icon from "../../../components/icon/icon"
import Section from "../../../components/section/section"
import TextBlock from "../../../components/text-block/text-block"

const ServicesBlock = ({ darkBackground, backgroundColor }) => {
    const openDropdown = (key) => {
        let col = document.getElementsByClassName("service")[key]
        console.log(col.classList);
        if (col.classList.value.includes("opened")) {
            col.classList.remove("opened");
        } else {
            col.classList.add("opened");
        }
    }

    return (
        <Section
            id="services"
            darkBackground={darkBackground}
            style={{ backgroundColor }}
        >
            <Container type="default">
                <div className="services-content">
                    <div className="services-left">
                        <TextBlock
                            title="Onze diensten."
                        >
                            Bij digitam gaan we tot het uiterste voor uw tevredenheid. Heeft u ons nodig voor iets anders? Neem gerust <Link to="/contact"><span className="highlight-blue">contact</span></Link> op.
                        </TextBlock>
                    </div>
                    <div className="services-right">
                        <div className="service service-graphics">
                            <div className="service-header" onClick={() => openDropdown(0)} onKeyDown={() => openDropdown(0)}>
                                <div className="service-icon">
                                    <Icon icon={<RiPenNibLine />} />
                                </div>
                                <div className="service-title"><h3>Graphic design</h3></div>
                            </div>
                            <div className="service-description">
                                <p>Wij creëren visueel gedreven content, zoals logo's, posters en infographics. Dit doen wij met industriestandaard programma's zoals Adobe Illustrator en Photoshop. </p>
                                <div className="service-link">
                                    <Link to="/graphic-design">Meer informatie</Link>
                            </div></div>

                        </div>
                        <div className="service service-webdev" onClick={() => openDropdown(1)} onKeyDown={() => openDropdown(1)}>
                            <div className="service-header">
                                <div className="service-icon">
                                    <Icon icon={<RiCodeSSlashLine />} />
                                </div>
                                <div className="service-title"><h3>Web development</h3></div>
                            </div>

                            <div className="service-description"><p>Bij digitam ontwerpen en realiseren wij moderne, snelle en betrouwbare websites. Of u nou een simpele statische website wilt, of ingewikkelde dynamische websites zoals een webshops, wij kunnen het!</p><div className="service-link">
                                <Link to="/web-development">Meer informatie</Link>
                            </div> </div>

                        </div>
                        <div className="service service-branding">
                            <div className="service-header" onClick={() => openDropdown(2)} onKeyDown={() => openDropdown(2)}>
                                <div className="service-icon">
                                    <Icon icon={<RiFingerprint2Line />} />
                                </div>
                                <div className="service-title">
                                    <h3>Branding</h3>
                                </div>
                            </div>

                            <div className="service-description"><p>Wij creëren een visuele identiteit, die past bij uw bedrijf en waarmee u zich kan onderscheiden van de concurrentie. Hiermee versterkt u uw merk en daarmee ook uw bedrijf.</p><div className="service-link">
                                <Link to="/graphic-design#branding-huisstijl">Meer informatie</Link>
                            </div></div>

                        </div>
                        <div className="service service-marketing">
                            <div className="service-header" onClick={() => openDropdown(3)} onKeyDown={() => openDropdown(3)}>
                                <div className="service-icon">
                                    <Icon icon={<RiChat3Line />} />
                                </div>
                                <div className="service-title"><h3>Marketing</h3></div>
                            </div>

                            <div className="service-description"><p>Voor meer (online) zichtbaarheid is goede marketing tegenwoordig erg belangrijk. Wij zorgen ervoor dat uw bedrijf  een connectie maakt met uw doelgroep om uw merk op te bouwen.</p><div className="service-link">
                                Meer informatie
                            </div></div>

                        </div>
                        
                        <div className="service service-softwaredev">
                            <div className="service-header" onClick={() => openDropdown(4)} onKeyDown={() => openDropdown(4)}>
                                <div className="service-icon">
                                    <Icon icon={<RiFileCodeLine />} />
                                </div>
                                <div className="service-title"><h3>Software development</h3></div>
                            </div>

                            <div className="service-description"><p>Wij ontwikkelen efficiente programma's, geschreven in onder andere Python, Java en JavaScript. Van CLI tools tot volledige GUI applicaties.</p> <div className="service-link">
                                Meer informatie
                            </div></div>

                        </div>

                        <div className="service service-seo">
                            <div className="service-header" onClick={() => openDropdown(5)} onKeyDown={() => openDropdown(5)}>
                                <div className="service-icon">
                                    <Icon icon={<RiSearchLine />} />
                                </div>
                                <div className="service-title"><h3>Zoekmachine optimalisatie</h3></div>
                            </div>

                            <div className="service-description"><p>Wij optimaliseren uw website om zo hoog mogelijk te komen staan in de zoekresultaten van Google. Dit vergroot uw online zichtbaarheid en zorgt voor meer potentiële klanten.</p>
                                <div className="service-link">
                                    <Link to="/web-development#SEO">Meer informatie</Link>
                            </div> </div>

                        </div>
                    </div>
                </div>

            </Container>
        </Section>
    );

}

export default ServicesBlock
