import React, { useEffect, useState } from "react"

import lottie from "lottie-web";

import Section from "../../../components/section/section"

import AnimationData from "../../../lottie-files/index/animatedLandingNew.json"

const ImageBlock = ({ darkBackground, backgroundColor }) => {
    const [lottiesActive, addActiveLottie] = useState(0);

    useEffect(() => {
        if (lottiesActive === 0) {
            addActiveLottie(lottiesActive + 1);
            lottie.loadAnimation({
                container: document.getElementById("lottie-container"),
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: AnimationData,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                    progressiveLoad: true
                }
            });
        }
        
    })

    return (
        <Section
            id="image-landing"
            darkBackground={darkBackground}
            style={{ backgroundColor }}
        >
            
                <div className="image-holder">
                    <div id="lottie-container"></div>
                </div>
                
                
           
        </Section>
    );
}

export default ImageBlock
