import PropTypes from "prop-types"
import React from "react"

const Button = ({ text, type, href}) => (
    <a href={href} className={"button " + type}>{ text }</a>
)

Button.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    href: PropTypes.string,
}

Button.defaultProps = {
    text: `Insert text`,
    type: `button-md`,
    href: `#`,
}

export default Button
