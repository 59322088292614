import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactBlock from "../blocks/general-blocks/contact-block/contact-block"

import LandingBlock from "../blocks/index-blocks/landing-block/landing-block"
import ImageBlock from "../blocks/index-blocks/image-block/image-block"
import ServicesBlock from "../blocks/index-blocks/services-block/services-block"
import PortfolioBlock from "../blocks/index-blocks/portfolio-block/portfolio-block"


import { colors } from "../imports/colors"
const IndexPage = () => (

    <Layout>
        <SEO 
            title="Grafisch vormgever &#38; Website maker" 
            lang="nl" 
            cannonicalUrl="https://digitam.nl"
        />
        <LandingBlock />
        <ImageBlock darkBackground={false} backgroundColor={colors["grey-00"]} />
        <ServicesBlock darkBackground={false}  backgroundColor={colors["grey-00"]} />
        <PortfolioBlock darkBackground={false} backgroundColor={colors["grey-00"]} />
        <ContactBlock darkBackground={true} backgroundColor={colors["blue-05"]}>
            Neem contact met ons op voor meer informatie, geheel vrijblijvend. <br />
            Contact opnemen kan door een email te sturen, of het formulier in te vullen.
        </ContactBlock>
    </Layout>
)

export default IndexPage
