import React, { useEffect, useState } from "react"


import { Link } from "gatsby"

import { RiCheckLine, RiMouseLine } from "react-icons/ri"


import Particles from 'react-particles-js';

import Typed from 'typed.js';


import Attributes from "../../../components/attributes/attributes"
import Button from "../../../components/button/button"
import Container from "../../../components/container/container"
import Icon from "../../../components/icon/icon"
import Section from "../../../components/section/section"


const pageTitle = () => (
    <>
        <h1>Wij zorgen dat uw bedrijf <span className='underline-blue-no-anim'>opvalt</span>.</h1>
    </>
);



const attributes = [
    {
        icon: <RiCheckLine />,
        title: "Ervaren.",
        text: "Wordpress, Django, React. Niets is voor ons onbekend",
    },
    {
        icon: <RiCheckLine />,
        title: "Resultaatgericht.",
        text: "Ons doel is om samen de beste resultaten te halen",
    },
    {
        icon: <RiCheckLine />,
        title: "Vernieuwend.",
        text: "Wij durven te vernieuwen. Durft u het ook?",
    },
   
]



const LandingBlock = () => {

    // This state is used to prevent the typer glitching
    // When it make multiple Typed objects and starts flickering
    const [typersActive, addActiveTyper] = useState(0);

    useEffect(() => {
        if (typersActive === 0) {
            addActiveTyper(typersActive + 1);
            const typed = new Typed('#typer', {
                strings: [
                    "<h1>Wij zorgen dat uw bedrijf <span class='underline-blue-no-anim'>opvalt</span>.</h1>",
                    "<h1>Wij zorgen dat uw bedrijf <span class='underline-blue-no-anim'>opvalt</span> <span class='highlight-blue'>met een mooi logo</span>.</h1>",
                    "<h1>Wij zorgen dat uw bedrijf <span class='underline-blue-no-anim'>opvalt</span> <span class='highlight-blue'>met een krachtige website</span>.</h1>",
                    "<h1>Wij zorgen dat uw bedrijf <span class='underline-blue-no-anim'>opvalt</span> <span class='highlight-blue'>met een Google Mijn Bedrijf listing</span>.</h1>",
                    "<h1>Wij zorgen dat uw bedrijf <span class='underline-blue-no-anim'>opvalt</span> <span class='highlight-blue'>met doeltreffende advertenties</span>.</h1>",
                    "<h1>Wij zorgen dat uw bedrijf <span class='underline-blue-no-anim'>opvalt</span> <span class='highlight-blue'>met een hoge positie in de zoekresultaten</span>.</h1>",
                    "<h1>Wij zorgen dat uw bedrijf <span class='underline-blue-no-anim'>opvalt</span> <span class='highlight-blue'>met een opvallend visitekaartje</span>.</h1>",
                    "<h1>Wij zorgen dat uw bedrijf <span class='underline-blue-no-anim'>opvalt</span> <span class='highlight-blue'>met een icoon op Google Maps</span>.</h1>",
                    "<h1>Wij zorgen dat uw bedrijf <span class='underline-blue-no-anim'>opvalt</span>. <br> <h2><span class='highlight-grey'>Ga digitaal met digitam</span></h2>",
                ],
                startDelay: 5000,
                typeSpeed: 80,
                backSpeed: 30,
                backDelay: 2000,
                smartBackspace: true,
                showCursor: false,
            });
        }
        
    })
    return (
        <Section id="landing">
            <Container type="default">
                <div className="landing-content">
                    <div id="typer" className="landing-content-header">
                        {pageTitle()}
                    </div>
                    <div className="landing-content-cta">
                        <a href="#portfolio" className="button button-primary button-solid">Bekijk ons werk</a>
                        <a href="#contact" className="button button-primary button-outlined">Of kom in contact met ons</a>
                    </div>
                    
                </div>
            </Container>
            <div className="landing-background" >
                <Particles
                    params={{
                        "detectRetina": true,
                        "fpsLimit": 60,
                        "particles": {
                            "collisions": {
                                "enable": false,
                                "mode": "bounce"
                            },
                            "color": {
                                "value": ["#0b7bfa", "#0bfa6f", "#faea0b", "#fa270b", "#fa0b5b"],
                                "animation": {
                                    "enable": false,
                                    "speed": 1,
                                    "sync": true
                                }
                            },
                            "links": {
                                "blink": false,
                                "color": {
                                    "value": "#ffffff"
                                },
                                "consent": false,
                                "distance": 150,
                                "enable": false,
                                "opacity": 0.4,
                                "shadow": {
                                    "blur": 5,
                                    "color": {
                                        "value": "lime"
                                    },
                                    "enable": false
                                },
                                "triangles": {
                                    "enable": false
                                },
                                "width": 1,
                                "warp": false
                            },
                            "move": {
                                "attract": {
                                    "enable": false,
                                    "rotate": {
                                        "x": 600,
                                        "y": 2000
                                    }
                                },
                                "direction": "none",
                                "enable": true,
                                "noise": {
                                    "delay": {
                                        "random": {
                                            "enable": false,
                                            "minimumValue": 0
                                        },
                                        "value": 0
                                    },
                                    "enable": false,
                                    "factor": {
                                        "horizontal": {
                                            "value": 50,
                                            "offset": 0
                                        },
                                        "vertical": {
                                            "value": 10,
                                            "offset": 40000
                                        }
                                    }
                                },
                                "outMode": "out",
                                "random": true,
                                "speed": 2,
                                "straight": false,
                                "trail": {
                                    "enable": false,
                                    "length": 10,
                                    "fillColor": {
                                        "value": "#ffffff"
                                    }
                                },
                                "vibrate": false,
                                "warp": false
                            },
                            "number": {
                                "density": {
                                    "enable": true,
                                    "area": 1500,
                                    "factor": 1000
                                },
                                "limit": 0,
                                "value": 120
                            },
                            "opacity": {
                                "animation": {
                                    "enable": true,
                                    "minimumValue": 0,
                                    "speed": 1,
                                    "sync": false
                                },
                                "random": {
                                    "enable": true,
                                    "minimumValue": .2
                                },
                                "value": 1
                            },
                            "rotate": {
                                "animation": {
                                    "enable": false,
                                    "speed": 0,
                                    "sync": false
                                },
                                "direction": "clockwise",
                                "random": false,
                                "value": 0
                            },
                            "shape": {
                                "options": {
                                    "character": {
                                        "fill": true,
                                        "close": true,
                                        "font": "Verdana",
                                        "style": "",
                                        "value": "*",
                                        "weight": "400"
                                    },
                                    "char": {
                                        "fill": true,
                                        "close": true,
                                        "font": "Verdana",
                                        "style": "",
                                        "value": "*",
                                        "weight": "400"
                                    },
                                    "image": {
                                        "fill": true,
                                        "close": true,
                                        "height": 100,
                                        "replaceColor": false,
                                        "src": "https://cdn.matteobruni.it/images/particles/github.svg",
                                        "width": 100
                                    },
                                    "images": {
                                        "fill": true,
                                        "close": true,
                                        "height": 100,
                                        "replaceColor": false,
                                        "src": "https://cdn.matteobruni.it/images/particles/github.svg",
                                        "width": 100
                                    },
                                    "polygon": {
                                        "fill": true,
                                        "close": true,
                                        "sides": 5
                                    },
                                    "star": {
                                        "fill": true,
                                        "close": true,
                                        "sides": 5
                                    }
                                },
                                "type": "circle"
                            },
                            "size": {
                                "animation": {
                                    "destroy": "none",
                                    "enable": false,
                                    "minimumValue": 0.3,
                                    "speed": 4,
                                    "startValue": "max",
                                    "sync": false
                                },
                                "random": {
                                    "enable": true,
                                    "minimumValue": 1
                                },
                                "value": 8
                            },
                            "stroke": {
                                "color": {
                                    "value": "#000000"
                                },
                                "width": 0,
                                "opacity": 1
                            },
                            "twinkle": {
                                "lines": {
                                    "enable": false,
                                    "frequency": 0.05,
                                    "opacity": 1
                                },
                                "particles": {
                                    "enable": false,
                                    "frequency": 0.05,
                                    "opacity": 1
                                }
                            }
                        },
                        "pauseOnBlur": true
                    }} />
            </div>
        </Section>
    )
}

export default LandingBlock
